import { render, staticRenderFns } from "./payment-failed.html?vue&type=template&id=3a206f5e&scoped=true&"
import script from "./payment-failed.js?vue&type=script&lang=js&"
export * from "./payment-failed.js?vue&type=script&lang=js&"
import style0 from "./payment-failed.scss?vue&type=style&index=0&id=3a206f5e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a206f5e",
  null
  
)

export default component.exports